import React, { useState } from "react";
import InputBox from "../Dashboard/Components/InputBox";
import SelectInput from "../Dashboard/Components/SelectInput";
import { addNewDoc } from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function IncidentForm({ setAddMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [activeLink, setActiveLink] = React.useState("No");
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [selectedIncident, setSelectedIncident] = React.useState("");
  const [editing, setEditing] = useState(false);

  const links = ["Yes", "No"];

  const incidentTypes = [
    "Theft",
    "Robbery",
    "Traffic Accident",
    "Vandalism",
    "Assault",
    "Suspicious Activity",
    "Homicide/Murder",
    "Fire",
    "Alarm & Scandal",
    "Others",
  ];
  const incidentOptions = incidentTypes.map((incident) => {
    return {
      label: incident,
      value: incident,
    };
  });

  const handleClose = () => {
    if (editing) {
      if (
        window.confirm(
          "Your changes will not be saved? Do you wish to continue?"
        )
      ) {
        setAddMode(false);
      } else {
        return;
      }
    } else {
      setAddMode(false);
    }
  };

  const handleChange = (e) => {
    setEditing(true);
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const docObject = {
      ...formData,
      policeReported: activeLink,
      incidentType: selectedIncident,
      createdBy: currentUser.uid,
      createdName: currentUser.displayName,
      createdEmail: currentUser.email,
      updateType: "Incident Report Created",
      createdAt: new Date().toLocaleString(),
      status: "Pending Investigation",
    };

    await addNewDoc("incidentReports", docObject);
    toast.success("Incident Report Submitted Successfully");
    setIsProcessing(false);
    setAddMode(false);
  };
  const handleSelectIncident = (selected) => {
    setEditing(true);
    setSelectedIncident(selected.value);
  };

  return (
    <div className="page relative flex items-start gap-4 flex-col lg:flex-row">
      <button className="close-button" onClick={handleClose}>
        Close
      </button>

      <div className="w-full lg:w-96 ">
        <div className="header mb-4">Incident Report</div>
        <form className="space-y-4">
          <SelectInput
            options={incidentOptions}
            name="type"
            label="Type of Incident"
            onChange={(selected) => handleSelectIncident(selected)}
            editable={!isProcessing}
          />
          <div>
            <div className="text-blue-900 font-bold uppercase">
              Police Notified?
            </div>
            <div className="flex items-center gap-4">
              {links.map((link, index) => (
                <button
                  type="button"
                  className={
                    activeLink === link ? "active-pill" : "inactive-pill"
                  }
                  key={index}
                  onClick={() => setActiveLink(link)}
                >
                  {link}
                </button>
              ))}
            </div>
          </div>
          <InputBox
            name="date"
            label="Date of Incident"
            type="date"
            onChange={handleChange}
            disabled={isProcessing}
            placeholder={"Enter date of incident"}
          />

          <InputBox
            name="time"
            label="Time of Incident"
            type="time"
            onChange={handleChange}
            disabled={isProcessing}
            placeholder={"Enter time of incident"}
          />
          <InputBox
            name="location"
            label="Location"
            type="text"
            placeholder={"Enter location of incident"}
            onChange={handleChange}
            disabled={isProcessing}
          />
        </form>
      </div>

      <div className="w-full">
        <div>
          <div className="text-blue-900 font-bold uppercase">
            Incident Narration
          </div>
          <textarea
            className="border rounded-lg p-2 w-full "
            rows={20}
            name="description"
            label="Incident Report"
            placeholder="Narrate incident here"
            id=""
            onChange={handleChange}
            disabled={isProcessing}
          ></textarea>
        </div>

        <div>
          <button
            className="blue-button w-full lg:w-fit"
            onClick={handleSubmit}
            disabled={isProcessing}
          >
            {isProcessing ? "Saving" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}
