import React, { useEffect, useState } from "react";
import UserScreenWindow from "./UserScreenWindow";
import PageModal from "../Dashboard/Components/PageModal";
import CarStickerApplication from "./CarStickerApplication";
import {
  getAllDocsSnap,
  queryAllDocsByFieldSnap,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";

export default function CarStickerPage() {
  const currentUser = useSelector((state) => state.userObject);
  const [addMode, setAddMode] = React.useState(false);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchApplications = async () => {
      await queryAllDocsByFieldSnap(
        "carStickers",
        "userId",
        "==",
        currentUser.uid,
        (data) => {
          setApplications(data);
          setLoading(false);
        }
      );
    };
    fetchApplications();
  }, []);

  return (
    <UserScreenWindow label="Car Sticker Applications">
      <div className="header">Car Sticker Application Page</div>
      <div>
        <button className="blue-button" onClick={() => setAddMode(true)}>
          New Application
        </button>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="mt-4">
          {applications.map((application, index) => (
            <div
              key={index}
              className="flex items-center gap-4 text-gray-600 text-xs card"
            >
              <div>{application.plateNumber}</div>
              <div>{application.carModel}</div>
              <div>{application.status}</div>
            </div>
          ))}
        </div>
      )}

      {addMode && (
        <PageModal>
          <CarStickerApplication setAddMode={setAddMode} />
        </PageModal>
      )}
    </UserScreenWindow>
  );
}
