import React, { useEffect, useState } from "react";
import UserScreenWindow from "./UserScreenWindow";
import UserPhoto from "../assets/blankphoto.webp";
import { useSelector } from "react-redux";
import { useRef } from "react";
import {
  queryDocByField,
  queryDocByFieldSnap,
  setDocData,
  storage,
} from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDocDataSnap } from "../../utils/firebase/Firebase.utils";
import AddOccupantFormUser from "./AddOccupantFormUser";
import PageModal from "../Dashboard/Components/PageModal";
import OccupantDetails from "./OccupantDetails";

export default function UserProfile() {
  const currentUser = useSelector((state) => state.userObject);
  const inputRef = useRef(null);
  const [progress, setProgress] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [userData, setUserData] = React.useState({});
  const [addMode, setAddMode] = React.useState(false);
  const [occupantsArray, setOccupantsArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOccupant, setSelectedOccupant] = useState({});
  const [viewMode, setViewMode] = useState(false);
  const [principalResident, setPrincipalResident] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await getDocDataSnap("users", currentUser.uid, (data) => {
        setUserData(data);
      });
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchOccupants = async () => {
      const occupantsPromises = await currentUser.linkedIdentifiers.map(
        async (identifier) => {
          const occupants = await queryDocByField(
            "residents",
            "identifier",
            "==",
            identifier
          );
          const docObject = {
            identifier: identifier,

            occupants: occupants[0].occupantsName.map((item) => {
              return {
                ...item,
                principalResident: occupants[0].id,
              };
            }),
          };
          return docObject;
        }
      );
      const occupants = await Promise.all(occupantsPromises);
      setOccupantsArray(occupants);
      setLoading(false);
    };
    fetchOccupants();
  }, [currentUser.linkedIdentifiers]);

  const handleUpload = async (e) => {
    //check if file is selected
    if (!e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];

    const storageRef = ref(storage, `userPhotos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await setDocData("users", currentUser.uid, { photoUrl: downloadURL });
          setProgress(0);
        });
      }
    );
  };

  const handleAddMode = () => {
    setAddMode(true);
  };

  const handleViewMode = (occupant) => {
    setViewMode(true);
    setSelectedOccupant(occupant);
  };

  return (
    <UserScreenWindow label="User Profile">
      <div className="flex flex-col p-4 text-xs text-gray-600">
        <div className="mt-4">
          <img
            src={userData.photoUrl || UserPhoto}
            alt=""
            className="rounded-full object-cover object-center"
            width={50}
            height={50}
            onClick={() => inputRef.current.click()}
          />
          {progress > 0 && (
            <progress
              value={progress}
              max="100"
              className="w-full h-2 bg-blue-900"
            />
          )}
          <input
            type="file"
            className="hidden"
            ref={inputRef}
            onChange={handleUpload}
            accept="image/*"
          />
          <div className="text-sm font-bold text-blue-900">
            {currentUser.displayName}
          </div>
          <div className="text-xs text-gray-400">
            {currentUser.linkedIdentifiers?.map((identifier) => (
              <div key={identifier}>{identifier}</div>
            ))}
          </div>
        </div>

        <div className="mt-4">
          <button className="blue-button" onClick={handleAddMode}>
            Register an Occupant
          </button>

          <div className="mt-4">
            <div className="header">My household occupants</div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="text-gray-600 text-sm font-bold">Loading...</div>
      ) : (
        occupantsArray.map((occupant, index) => {
          return (
            <div key={index}>
              <div className="text-gray-600 text-xs font-bold mt-2">
                {occupant.identifier}
              </div>
              <div className="capitalize ">
                {occupant.occupants.map((occ, index) => (
                  <div
                    key={index}
                    onClick={() => handleViewMode(occ)}
                    className="text-xs bg-white px-2 py-1 rounded-lg mt-2 cursor-pointer hover:bg-gray-300"
                  >
                    {occ.firstName} {occ.lastName} - {occ.relationship}
                  </div>
                ))}
              </div>
            </div>
          );
        })
      )}

      {viewMode && (
        <PageModal>
          <OccupantDetails
            occupant={selectedOccupant}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}

      {addMode && (
        <PageModal>
          <AddOccupantFormUser setAddMode={setAddMode} />
        </PageModal>
      )}
    </UserScreenWindow>
  );
}
