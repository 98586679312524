import React, { useEffect } from "react";
import ResidentInformationPage from "./ResidentInformationPage";
import OccupantsInformationPage from "./OccupantsInformationPage";
import VehiclesInformationPage from "./VehiclesInformationPage";
import HistoryInformationPage from "./HistoryInformationPage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { deleteDocById } from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";

export default function ResidentDetailsFullpage() {
  const [activeLink, setActiveLink] = React.useState("Resident Information");
  const currentUser = useSelector((state) => state.userObject);
  const { id } = useParams();
  const navigate = useNavigate();

  const links = [
    "Resident Information",
    "Occupants Information",
    "Vehicles",
    "History",
  ];

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this resident?")) {
      await deleteDocById("residents", id);
      navigate("/resident-management?link=Owners");
      toast.success("Resident deleted successfully");
    }
  };

  return (
    <div className="text-gray-600 text-left mt-4 text-xs ">
      <div className="underline text-xs mt-4">
        <Link to="/resident-management?link=Owners">
          Back to Residents Management
        </Link>
      </div>

      <div className="header mt-4">Resident Details</div>
      {currentUser.roles.includes("super-admin") && (
        <button className="underline" onClick={handleDelete}>
          Delete
        </button>
      )}

      <div className="flex items-center bg-gray-200 p-1 mb-4 w-fit mt-4 rounded-xl gap-4 border border-gray-400">
        {links.map((link, index) => (
          <div
            key={index}
            className={`p-2 text-center cursor-pointer ${
              activeLink === link
                ? "bg-white p-2 shadow-lg text-blue-900 font-bold rounded-lg"
                : "font-bold text-gray-400 hover:rounded-lg hover:bg-white hover:shadow-lg"
            }`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      {activeLink === "Resident Information" && (
        <div>
          <ResidentInformationPage />
        </div>
      )}

      {activeLink === "Occupants Information" && (
        <div>
          <OccupantsInformationPage />
        </div>
      )}

      {activeLink === "Vehicles" && (
        <div>
          <VehiclesInformationPage />
        </div>
      )}

      {activeLink === "History" && (
        <div>
          <HistoryInformationPage />
        </div>
      )}
    </div>
  );
}
