import React, { useEffect } from "react";
import InputBox from "../Dashboard/Components/InputBox";
import { useSelector } from "react-redux";
import SelectInput from "../Dashboard/Components/SelectInput";
import Label from "../Dashboard/Components/Label";
import {
  getDocDataSnap,
  queryDocByFieldSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { v4 } from "uuid";

export default function AddOccupantFormUser({ setAddMode }) {
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const [selectedIdentifier, setSelectedIdentifier] = React.useState(null);
  const [selectedResident, setSelectedResident] = React.useState({});
  const [currentOccupants, setCurrentOccupants] = React.useState([]);
  const [assignedId, setAssignedId] = React.useState(v4());

  useEffect(() => {
    const fetchResidentData = async () => {
      await queryDocByFieldSnap(
        "residents",
        "identifier",
        "==",
        selectedIdentifier,
        (data) => {
          setSelectedResident(data[0]?.id);
          setCurrentOccupants(data[0]?.occupantsName || []);
        }
      );
    };
    fetchResidentData();
  }, [selectedIdentifier]);

  const identifierOptions = currentUser.linkedIdentifiers.map((id) => {
    return { value: id, label: id };
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      id: assignedId,
    };
    const newOccupantsArray = [...currentOccupants, docObject];

    await setDocData("residents", selectedResident, {
      occupantsName: newOccupantsArray,
    });
    toast.success("Occupant added successfully");
    setIsProcessing(false);
    setAddMode(false);
  };

  return (
    <div className="bg-white p-4 rounded-md shadow-md w-full min-h-screen relative text-xs text-gray-600">
      <div className="header">Add Occupant</div>
      <button className="close-button" onClick={() => setAddMode(false)}>
        Close
      </button>

      <form action="" onSubmit={handleSubmit}>
        <div className="space-y-2">
          <Label label="Select Lot Number" />
          <SelectInput
            options={identifierOptions}
            onChange={(e) => setSelectedIdentifier(e.value)}
          />
          <InputBox
            label="First Name"
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            required
            onChange={handleChange}
          />
          <InputBox
            label="Middle Name"
            placeholder="Middle Name"
            name="middleName"
            value={formData.middleName}
            required
            onChange={handleChange}
          />
          <InputBox
            label="Last Name"
            placeholder="Last Name"
            name="lastName"
            onChange={handleChange}
            value={formData.lastName}
            required
          />
          <InputBox
            label="Birthday"
            type="date"
            placeholder="Birth Day"
            name="birthday"
            value={formData.birthday}
            required
            onChange={handleChange}
          />
          <InputBox
            label="Email Address"
            type="email"
            placeholder="Email Address"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <InputBox
            label="Relationship"
            placeholder="e.g. Wife, Daughter, Son, etc"
            name="relationship"
            value={formData.relationship}
            required
            onChange={handleChange}
          />
          <div className="">
            <button
              type="submit"
              className="blue-button w-full mt-4"
              disabled={isProcessing}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
