import React from "react";

export default function OccupantDetails({ occupant, setViewMode }) {
  const { firstName, middleName, lastName, birthday, relationship } = occupant;

  return (
    <div className="bg-gray-100 p-4 rounded-lg w-full min-h-screen text-xs text-gray-600 relative">
      <div className="header">Occupant Details</div>
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>
      <div className="mt-4">
        <div>
          {firstName} {middleName} {lastName}
        </div>
        <div>{birthday}</div>
        <div>Relation: {relationship}</div>
      </div>
    </div>
  );
}
