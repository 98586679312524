import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { FiChevronLeft } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { AnimatePresence, motion } from "framer-motion";
import { CiUser } from "react-icons/ci";
import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";

import { getDocDataSnap } from "../../utils/firebase/Firebase.utils";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

function Profile() {
  const currentUser = useSelector((state) => state.userObject);
  const dispatch = useDispatch();
  const [userData, setUserData] = React.useState({});

  const handleLogout = () => {
    dispatch(loadUser({}));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await getDocDataSnap("users", currentUser.uid, (data) => {
        setUserData(data);
      });
    };
    fetchUserData();
  }, []);

  const invitationLink = `https://tali.elegho.app/invite/${currentUser.uid}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(invitationLink);
    toast.success("Link copied to clipboard");
  };

  return (
    <motion.div
      className="bg-gray-100 h-screen relative"
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.3, type: "tween" }}
      ease="easeInOut"
    >
      <div
        className="w-full bg-blue-900 h-[50px] flex items-center text-white"
        onClick={() => window.history.back()}
      >
        <FiChevronLeft className="text-white text-2xl" />
        <div>Profile</div>
      </div>
      <div className="bg-blue-900 text-white h-[200px] rounded-xl w-[90%] mt-4 mx-auto flex flex-col items-center justify-center">
        <img
          src={
            userData.photoUrl ||
            "https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg?semt=ais_hybrid"
          }
          alt="profile"
          className="rounded-full h-[50px] w-[50px] bg-gray-50 object-cover object-center"
        />
        <div>{currentUser.displayName || "John Doe"}</div>
        <div className="text-xs text-blue-400">
          {userData.linkedIdentifiers?.map((identifier) => (
            <div key={identifier}>{identifier}</div>
          ))}
        </div>
        <div className="text-sm flex items-center gap-2">
          <BsTelephone className="text-white" />
          {userData.telephone || "No Phone Number"}
        </div>
        <div className="text-sm flex items-center gap-2">
          <MdOutlineMail className="text-white" />
          {userData.email}
        </div>
      </div>

      <div className="border border-blue-900 rounded-xl mt-4 divide-y divide-blue-900 py-2 flex flex-col text-left text-blue-900 mx-4">
        <div className="p-2 flex items-center justify-between">
          <Link to="/userprofile" className="flex-1">
            <div className="flex items-center justify-normal gap-4">
              <CiUser className="text-blue-900" />
              Profile
            </div>
          </Link>
          <MdOutlineArrowForwardIos className="text-blue-900" />
        </div>
        <div className="p-2 flex items-center justify-between">
          <div className="flex items-center justify-normal gap-4">
            <IoLockClosedOutline className="text-blue-900" />
            Password
          </div>
          <MdOutlineArrowForwardIos className="text-blue-900" />
        </div>
      </div>

      {!currentUser.principalUser && (
        <>
          <div className="mt-4 text-gray-600 text-xs">
            Invite your family members by sending them this link
          </div>
          <div className="flex items-center gap-4 mt-4 justify-between px-2">
            <input
              type="text"
              value={invitationLink}
              className="text-gray-600 px-2 py-1 rounded-lg flex-1"
              disabled={true}
            />
            <button className="blue-button" onClick={handleCopy}>
              Copy
            </button>
          </div>
        </>
      )}

      <div>
        <button
          className=" text-blue-900 p-2 rounded mt-2"
          onClick={handleLogout}
        >
          Log Out
        </button>
      </div>
    </motion.div>
  );
}

export default Profile;
