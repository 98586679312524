import React from "react";
import SignupForm from "../Login/SignupForm";
import InvitationForm from "./InvitationForm";

export default function InvitationPage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-8 bg-gray-100">
      <div className="text-blue-900 font-bold">
        Congratulations youre invited by a family member to use Tali Connect
      </div>

      <div className="my-4">Sign up below to get started</div>

      <InvitationForm />
    </div>
  );
}
