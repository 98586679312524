import React, { useEffect, useState } from "react";
import SelectInput from "./Components/SelectInput";
import {
  setDocData,
  getDocDataSnap,
} from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { addNewNotification } from "../../utils/EleghoUtils";

export default function ConcernsPageDetails({ concern, setViewMode }) {
  const [formData, setFormData] = useState(concern);
  const [comments, setComments] = useState(concern.comments || []);
  const [newComment, setNewComment] = useState("");
  const [dispatchee, setDispatchee] = useState(concern?.dispatchee || "");
  const [newStatus, setNewStatus] = useState(concern.status);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [department, setDepartment] = useState("");
  const [resolution, setResolution] = useState(concern.resolution || "");
  const [editMode, setEditMode] = useState(false);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchConcern = async () => {
      await getDocDataSnap("concerns", concern.id, (data) => {
        setFormData(data);
        setComments(data.comments || []);
        setDispatchee(data?.dispatchee || "");
        setIsLoading(false);
      });
    };
    fetchConcern();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const dispatchOptions = [
    {
      label: "Engineering",
      value: "Engineering",
    },
    {
      label: "Security",
      value: "Security",
    },
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Finance",
      value: "Finance",
    },
    {
      label: "Facilities",
      value: "Facilities",
    },
  ];

  const statusList = ["Pending", "Processing", "Dispatched", "Resolved"];

  const statusOptions = statusList.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const handleDispatch = async () => {
    setIsProcessing(true);
    const docObject = {
      ...formData,
      status: "Dispatched",
      dispatchee: department,
      updatedAt: new Date().toLocaleString(),
      updatedBy: currentUser.uid,
      updatedName: currentUser.displayName,
      updatedEmail: currentUser.email,
      updateType: `Dispatched to ${department}`,
    };

    await setDocData("concerns", concern.id, docObject);
    setIsProcessing(false);
    toast.success(`Dispatched to ${department}`);
  };

  const handleSelectDepartment = async (selected) => {
    setDepartment(selected);
  };

  const handleSelectStatus = (selected) => {
    setNewStatus(selected);
  };

  const handleUpdateStatus = async () => {
    let docObject = {};
    if (newStatus === "Resolved") {
      docObject = {
        ...formData,
        status: newStatus,
        updatedBy: currentUser.uid,
        updatedName: currentUser.displayName,
        updatedAt: new Date().toLocaleString(),
        resolvedAt: new Date().toLocaleString(),
      };
    } else {
      docObject = {
        ...formData,
        status: newStatus,
        updatedBy: currentUser.uid,
        updatedName: currentUser.displayName,
        updatedAt: new Date().toLocaleString(),
        resolvedAt: "Not yet resolved",
      };
    }

    await setDocData("concerns", concern.id, docObject);

    // send notification to user
    const notification = {
      dateTime: new Date(),
      user: formData.user,
      subject: "Concern Resolution Updated",
      message: `Your concern with reference number ${concern.id.slice(
        -8
      )} has been updated to ${newStatus}`,
    };
    await addNewNotification(notification);
    toast.success("Status Successfully Updated");
  };

  const handleNewComment = (e) => {
    setNewComment(e.target.value);
  };

  const handleAddComment = async () => {
    const docObject = {
      message: newComment,
      createdBy: currentUser.displayName,
      createdAt: new Date().toLocaleString(),
    };

    await setDocData("concerns", concern.id, {
      comments: [...comments, docObject],
    });
    toast.success("Comment Succesfully Added");
    setNewComment("");
  };

  const handleChangeResolution = (e) => {
    setResolution(e.target.value);
  };

  const handleResolutionUpdate = async () => {
    setEditMode(!editMode);

    if (editMode) {
      const docObject = {
        ...formData,
        resolution: resolution,
        updatedAt: new Date().toLocaleString(),
        updatedBy: currentUser.uid,
        updatedName: currentUser.displayName,
        updatedEmail: currentUser.email,
        updateType: "Resolution is Updated",
      };

      await setDocData("concerns", concern.id, docObject);

      toast.success("Resolution is Updated");

      setEditMode(!editMode);
    }
  };

  console.log(formData);

  return (
    <div className="w-full min-h-screen bg-white rounded-lg p-4 relative flex lg:flex-row flex-col items-start lg:gap-4 ">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>

      <div>
        <div className="header pt-8">Concern Details</div>

        <div className="flex items-center justify-between w-80">
          <div>Reference Number</div>
          <div>{concern.id.slice(-8)}</div>
        </div>

        <div className="flex items-center justify-between w-80">
          <div>Type of Concern</div>
          <div>{formData.concern}</div>
        </div>

        <div className="flex flex-col items-start w-80 mb-4 mt-2">
          <div>Description</div>
          <div>{formData.description}</div>
        </div>

        <div className="flex items-center justify-between w-80">
          <div>Date Filed</div>
          <div>{formData.createdAt}</div>
        </div>

        <div className="flex items-center justify-between w-80">
          <div>Assigned to</div>
          <div>{formData?.dispatchee || "Not Dispatched"}</div>
        </div>

        <div className="header mt-4">Image or Video</div>
        <div className="flex items-center justify-between w-80 mt-4">
          <div>Image</div>
          {formData.image ? (
            <a
              href={concern?.image}
              target="_blank"
              className={` p-2 rounded-lg ${
                formData.image
                  ? "bg-green-400"
                  : "bg-gray-200 cursor-not-allowed"
              }`}
            >
              {formData.image ? "View Image" : "No Image Uploaded"}
            </a>
          ) : (
            <div
              className={` p-2 rounded-lg ${
                formData.image
                  ? "bg-green-400"
                  : "bg-gray-200 cursor-not-allowed"
              }`}
            >
              No Image Uploaded
            </div>
          )}
        </div>

        <div className="flex items-center justify-between w-80 mt-4">
          <div>Video</div>
          {formData.video ? (
            <a
              href={formData?.video}
              target="_blank"
              className={` p-2 rounded-lg ${
                formData.video ? "bg-green-400" : "bg-gray-200"
              }`}
            >
              View Video
            </a>
          ) : (
            <div
              className={` p-2 rounded-lg ${
                formData.video
                  ? "bg-green-400"
                  : "bg-gray-200 cursor-not-allowed"
              }`}
            >
              No Video Uploaded
            </div>
          )}
        </div>

        <div className="header mt-4">Status</div>
        <div className={`capitalize ${formData.status.toLowerCase()}`}>
          {formData.status}
        </div>

        {/* Actions */}
        <div className="header mt-4">Actions</div>
        <div className="text-gray-600 font-bold mt-2">
          Dispatch this action to{" "}
        </div>
        <div className=" w-80">
          <div className="max-w-80">
            <SelectInput
              options={dispatchOptions}
              onChange={(selected) => handleSelectDepartment(selected.value)}
            />
          </div>
          <button
            className={`mt-2 blue-button disabled:bg-opacity-50 ${
              isProcessing ? "bg-opacity-50" : ""
            }`}
            disabled={isProcessing}
            onClick={handleDispatch}
          >
            {isProcessing ? "Dispatching.." : "Dispatch"}
          </button>
        </div>

        <div className="header mt-4">Update Status</div>

        <div className="max-w-80">
          <SelectInput
            options={statusOptions}
            onChange={(selected) => handleSelectStatus(selected.value)}
          />
          <button className="mt-2 blue-button " onClick={handleUpdateStatus}>
            Update
          </button>
        </div>
      </div>

      {/* Comments */}

      <div className="pt-8 w-full max-w-[1080px]">
        <div className="header">Comments</div>
        <div>
          <textarea
            name="comment"
            value={newComment}
            placeholder="Add comment here"
            rows={4}
            onChange={handleNewComment}
            className="border w-full rounded-lg p-2"
            id=""
          ></textarea>
          <button className="blue-button" onClick={handleAddComment}>
            Add Comment
          </button>
        </div>

        {comments.map((comment, index) => (
          <div
            key={index}
            className="bg-gray-100 w-full p-2 rounded-md shadow-sm mt-2"
          >
            <div>{comment.createdBy}</div>
            <div>{comment.message}</div>
            <div>{comment.createdAt}</div>
          </div>
        ))}

        <div className="mt-4">
          <div className="header">Resolution Report</div>
          <div className="mt-4">
            <textarea
              name="resolution"
              rows={6}
              value={formData?.resolution}
              id=""
              disabled={!editMode}
              onChange={handleChangeResolution}
              className="w-full border p-2 rounded-lg"
              placeholder="Add resolution report here"
            ></textarea>
            <button className="blue-button" onClick={handleResolutionUpdate}>
              {editMode ? "Update Resolution" : "Edit Resolution Report"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
