import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getAllDocs,
  getAllDocsSnap,
} from "../../../utils/firebase/Firebase.utils";
import { Link } from "react-router-dom";
import NotificationCard from "../../User/NotificationCard";

export default function NotificationWeb() {
  const [notifications, setNotifications] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchNotifications = async () => {
      const data = await getAllDocsSnap("notifications", (data) => {
        //filter active requests by email
        const userNotifications = data.filter(
          (notification) => notification.user === "admin@tali.com"
        );

        //sort notifications by date
        userNotifications.sort((a, b) => b.dateTime - a.dateTime);

        setNotifications(userNotifications);
        setIsLoading(false);
      });
    };
    fetchNotifications();
  }, []);

  if (isLoading) {
    return (
      <div label="Notifications">
        <div>Loading...</div>
      </div>
    );
  }

  console.log(notifications);

  return (
    <div label="Notifications ">
      {notifications.length === 0 && (
        <div className="text-center text-gray-500 mt-4">
          No notifications available
        </div>
      )}

      <div className="space-y-2 mt-4">
        {notifications.map((notification, index) => (
          <Link key={index} to={`/notif-details/${notification.id}`}>
            <NotificationCard notification={notification} />
          </Link>
        ))}
      </div>
    </div>
  );
}
