import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import { FaCar } from "react-icons/fa";
import PageModal from "../Dashboard/Components/PageModal";
import CarStickerApplication from "./CarStickerApplication";
import { useNavigate } from "react-router-dom";

export default function OtherRequestServices() {
  const [addMode, setAddMode] = React.useState(false);
  const navigate = useNavigate();

  return (
    <UserScreenWindow label="Other Requests">
      <div className="header">Other Request for Services</div>

      <div
        onClick={() => navigate("/car-sticker")}
        className="text-xs flex flex-col items-center justify-center w-20 h-20 bg-green-100 rounded-lg hover:bg-green-200 cursor-pointer"
      >
        <div className="text-4xl">
          <FaCar />
        </div>

        <div className="text-xs font-semibold text-center">
          Apply for Car Sticker
        </div>
      </div>

      {addMode && (
        <PageModal>
          <CarStickerApplication setAddMode={setAddMode} />
        </PageModal>
      )}
    </UserScreenWindow>
  );
}
