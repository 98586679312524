import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BlankPhoto from "../assets/blankphoto.webp";
import {
  getDocDataSnap,
  setDocData,
  storage,
} from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function ProfileSettings() {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      await getDocDataSnap("users", currentUser.uid, (data) => {
        setUserData(data);
        setIsLoading(false);
      });
    };
    fetchUserData();
  }, []);

  const handleUpload = async (e) => {
    if (!e.target.files[0]) return;

    const file = e.target.files[0];
    const storageRef = ref(storage, `userPhotos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await setDocData("users", currentUser.uid, {
            photoUrl: downloadURL,
          });
          setProgress(0);
        });
      }
    );
  };

  return (
    <div className="space-y-4 text-left text-xs text-gray-600">
      <div className="header">My Profile</div>

      <div>
        <img
          src={userData.photoUrl || BlankPhoto}
          className="rounded-full w-36 bg-gray-200 h-36 object-cover"
          alt=""
        />
        <div className="font-bold">Upload Profile Photo</div>
        <input
          type="file"
          placeholder="Upload Profile Picture"
          onChange={handleUpload}
        />
        {progress > 0 && <progress value={progress} max="100" />}
      </div>

      <div>
        <div className="flex items-center gap-8">
          <div>Display Name</div>
          <div>{currentUser.displayName}</div>
        </div>
        <div className="flex items-center gap-8">
          <div>Email Address</div>
          <div>{currentUser.email}</div>
        </div>
        <div className="flex items-center gap-8">
          <div>Access Role</div>
          <div>
            {currentUser.roles.map((role, index) => (
              <span className="" key={index}>
                {role}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSettings;
