import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { Link } from "react-router-dom";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import BlankPhoto from "../assets/blankphoto.webp";

import { IoMdMailUnread } from "react-icons/io";
import { IoIosSettings } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { RiLogoutBoxRFill } from "react-icons/ri";
import toast from "react-hot-toast";

function UserBar() {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [lastItem, setLastItem] = React.useState(null);
  const [users, setUsers] = React.useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      await getAllDocsSnap("users", (data) => {
        setUsers(data);
        setLoading(false);
      });
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      await getAllDocsSnap("notifications", (data) => {
        const filteredNotifications = data.filter(
          (item) => item.read === false && item.user === "admin@tali.com"
        );
        setNotifications(filteredNotifications.length);
        setLastItem(filteredNotifications[filteredNotifications.length - 1]);
      });
    };
    fetchNotifications();
  }, []);

  const userPhoto = users.find(
    (user) => user.email === lastItem?.from
  )?.photoUrl;

  useEffect(() => {
    if (notifications > 0 && loading === false) {
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <img
                  className="h-10 w-10 rounded-full"
                  src={userPhoto || BlankPhoto}
                  alt=""
                />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900">
                  New Notification Received
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  {lastItem?.subject}
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Close
            </button>
          </div>
        </div>
      ));
    }
  }, [notifications]);

  const handleLogout = () => {
    dispatch(loadUser({}));
  };

  console.log(userPhoto);

  return (
    <div className="flex items-center justify-center gap-2 text-gray-500 ">
      <div className="hover:bg-gray-400 hover:text-white cursor-pointer p-2 rounded-lg text-xl">
        <Link to="/notifications" className="relative">
          <IoMdMailUnread />
          {notifications > 0 && (
            <span className="bg-red-500 text-white text-xs rounded-full px-2 absolute -top-2 -right-2">
              {notifications}
            </span>
          )}
        </Link>
      </div>

      <div className="hover:bg-gray-400 hover:text-white cursor-pointer p-2 rounded-lg text-xl">
        <Link to="/settings?link=setup">
          <IoIosSettings />
        </Link>
      </div>
      <div className="hover:bg-gray-400 hover:text-white cursor-pointer p-2 rounded-lg text-xl">
        <Link to="/settings?link=profile">
          <FaUser />
        </Link>
      </div>
      <button
        className="hover:bg-gray-400 hover:text-white cursor-pointer p-2 rounded-lg text-xl"
        onClick={handleLogout}
      >
        <RiLogoutBoxRFill />
      </button>
    </div>
  );
}

export default UserBar;
