import React, { useEffect } from "react";
import PageModal from "./Components/PageModal";
import BillingPageDetails from "./Billings/BillingPageDetails";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";

function Billing() {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [ViewMode, setViewMode] = React.useState(false);
  const [selectedBilling, setSelectedBilling] = React.useState(null);
  const [bills, setBills] = React.useState([]);
  const [loading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchBillings = async () => {
      await getAllDocsSnap("billings", (data) => {
        // sort billings
        data.sort(
          (a, b) => b.soaNumber.split("-")[1] - a.soaNumber.split("-")[1]
        );

        setBills(data);

        setIsLoading(false);
      });
    };
    fetchBillings();
  }, []);

  // const billings = [
  //   {
  //     id: 1,
  //     soaNo: "SOA-0001",
  //     unitNo: "Phase 3 Block 1 Lot 1",
  //     billingDate: "2021-10-01",
  //     status: "Paid",
  //     amount: 1000,
  //     paymentDate: "2021-10-01",
  //   },
  //   {
  //     id: 2,
  //     soaNo: "SOA-0002",
  //     unitNo: "Phase 3 Block 1 Lot 2",
  //     billingDate: "2021-10-01",
  //     status: "Unpaid",
  //     amount: 2000,
  //     paymentDate: "2021-10-01",
  //   },
  //   {
  //     id: 3,
  //     soaNo: "SOA-0003",
  //     unitNo: "Phase 3 Block 1 Lot 3",
  //     billingDate: "2021-10-01",
  //     status: "Paid",
  //     amount: 3000,
  //     paymentDate: "2021-10-01",
  //   },
  //   {
  //     id: 4,
  //     soaNo: "SOA-0004",
  //     unitNo: "Phase 3 Block 1 Lot 4",
  //     billingDate: "2021-10-01",
  //     status: "Unpaid",
  //     amount: 4000,
  //     paymentDate: "2021-10-01",
  //   },
  // ];

  const billings = bills.map((bill) => {
    return {
      id: bill.id,
      soaNo: bill.soaNumber,
      name: bill.name,
      unitNo: bill.identifier,
      billingDate: bill.dueDate,
      status: bill.status,
      amount: bill.amount,
      paymentDate: bill.dueDate,
    };
  });

  const tableHeaders = [
    "SOA No.",
    "Lot No.",
    "Name",
    "Billing Date",
    "Status",
    "Amount",
    "Payment Date",
    "Actions",
  ];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredBillings = billings.filter(
    (billing) =>
      billing.soaNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      billing.unitNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      billing.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleView = (billing) => {
    setSelectedBilling(billing);
    setViewMode(true);
  };

  return (
    <div className="text-blue-900 p-2 space-y-2">
      <div className="flex items-center justify-between">
        <div className="font-bold text-xl">Billing</div>
        <div>
          Dues billing automatically computes based on rate and area in sqms of
          lots on an annual basis
        </div>
        <div className="flex items-center justify-normal gap-4">
          {/* <button className="blue-button">
            Create Billing
          </button>
          <button className="blue-button">
            Download PDF Report
          </button> */}
        </div>
      </div>
      <div className="border border-gray-300 p-2 rounded-lg flex items-center justify-between ">
        <input
          type="search"
          placeholder="Search by Billing No. or Name"
          className="rounded-lg border border-gray-300 px-4 py-2 min-w-96"
          onChange={handleSearch}
        />
        {/* <button>Filter</button> */}
      </div>

      {/* mapping of billings */}
      <div className="text-xs">
        <table className="w-full border border-gray-300">
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} className="border border-gray-300 p-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredBillings.map((billing, index) => (
              <tr key={index} className="border border-gray-300">
                <td className="border border-gray-300 p-2">{billing.soaNo}</td>
                <td className="border border-gray-300 p-2">{billing.unitNo}</td>
                <td className="border border-gray-300 p-2">{billing.name}</td>
                <td className="border border-gray-300 p-2">
                  {billing.billingDate}
                </td>
                <td className="border border-gray-300 p-2">{billing.status}</td>
                <td className="border border-gray-300 p-2">{billing.amount}</td>
                <td className="border border-gray-300 p-2">
                  {billing.paymentDate}
                </td>
                <td className="border border-gray-300 p-2">
                  <button
                    className="bg-blue-900 text-white px-4 py-2 rounded-md"
                    onClick={() => handleView(billing)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {ViewMode && (
        <PageModal>
          <BillingPageDetails
            data={selectedBilling}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}

export default Billing;
