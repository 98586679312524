import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { getAllDocs } from "../../utils/firebase/Firebase.utils";
import NotificationCard from "./NotificationCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function NotificationsUser() {
  const [notifications, setNotifications] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchNotifications = async () => {
      const data = await getAllDocs("notifications");

      //filter active requests by email
      const userNotifications = data.filter(
        (notification) => notification.user === currentUser.email
      );

      //sort notifications by date
      userNotifications.sort((a, b) => b.dateTime - a.dateTime);

      setNotifications(userNotifications);
      setIsLoading(false);
    };
    fetchNotifications();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Notifications">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }
  return (
    <UserScreenWindow label="Notifications">
      {notifications.length === 0 && (
        <div className="text-center text-gray-500 mt-4">
          No notifications available
        </div>
      )}

      <div className="space-y-2 mt-4">
        {notifications.map((notification, index) => (
          <Link key={index} to={`/notif-details/${notification.id}`}>
            <NotificationCard notification={notification} />
          </Link>
        ))}
      </div>
    </UserScreenWindow>
  );
}

export default NotificationsUser;
