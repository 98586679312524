import toast from "react-hot-toast";
import { addNewDoc } from "./firebase/Firebase.utils";

export const addNewNotification = async (notification) => {
  const newNotification = {
    ...notification,
    read: false,
  };
  await addNewDoc("notifications", newNotification);
};

export const convertTimeStamptoDate = (timestamp) => {
  const date = timestamp.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return date;
};
