import React from "react";

function BillingCard({ billing }) {
  //date firebase timestamp

  return (
    <div className="bg-white rounded-xl p-2 shadow-sm hover:bg-blue-300 text-gray-600 text-sm mt-2 relative">
      <div className="uppercase font-bold text-xs">{billing.identifier}</div>
      <div>Due Date: {billing.dueDate}</div>
      <div className="capitalize">Billing for: {billing.billingType}</div>
      <div className="capitalize">{billing.description}</div>
      <div className={`${billing.status} capitalize absolute top-1 right-2`}>
        {billing.status}
      </div>

      <div className="font-bold">
        Php {parseFloat(billing.amount).toLocaleString()}
      </div>
    </div>
  );
}

export default BillingCard;
