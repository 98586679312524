import React, { useEffect } from "react";
import InputBox from "../Components/InputBox";
import {
  setDocData,
  getDocDataSnap,
} from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { storage } from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";

export default function VehicleDetailsPage({ vehicle, setViewMode }) {
  const [formData, setFormData] = React.useState(vehicle);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [activeLink, setActiveLink] = React.useState("Vehicle Information");
  const [editMode, setEditMode] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [activeUpload, setActiveUpload] = React.useState("");
  const { id } = useParams();
  const currentUser = useSelector((state) => state.userObject);
  const [uploadMode, setUploadMode] = React.useState(false);

  useEffect(() => {
    const fetchVehicleData = async () => {
      await getDocDataSnap("vehicles", vehicle.id, (data) => {
        setFormData(data);
        setIsLoading(false);
      });
    };
    fetchVehicleData();
  }, []);

  const documents = formData.documents || {};
  const links = ["Vehicle Information", "Violations"];
  const requiredDocuments = ["OR/CR", "Driver's License", "Insurance"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      updatedAt: new Date().toLocaleString(),
      updatedBy: currentUser.uid,
      updatedByName: currentUser.displayName,
    };
    await setDocData("vehicles", vehicle.id, docObject);

    toast.success("Vehicle details updated successfully");
    setIsProcessing(false);
    setEditMode(false);
  };
  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleUploadMode = () => {
    setUploadMode(!uploadMode);
  };

  const handleUpLoad = async (e, doc) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `vehicles/${id}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setActiveUpload(doc);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
        }
      },
      (error) => {
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          const updatedDocuments = {
            ...documents,
            [doc]: downloadURL,
          };

          await setDocData("vehicles", vehicle.id, {
            documents: updatedDocuments,
          });
          setProgress(0);
          setActiveUpload("");
          toast.success("Document uploaded successfully");
        });
      }
    );
  };

  return (
    <div className="w-full bg-white rounded-md min-h-screen relative pt-8 p-4">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>
      <div className="header">Vehicle Details</div>

      <div className="mt-8 links-container">
        {links.map((link, index) => (
          <div
            className={
              activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
            }
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      <div className="mt-8">
        {activeLink === "Vehicle Information" && (
          <>
            <button
              className={editMode ? "underline" : "blue-button"}
              onClick={handleEditMode}
            >
              {editMode ? "Cancel Editing" : "Edit"}
            </button>

            {/* form for the information */}
            <form onSubmit={handleSubmit} className="mt-4 space-y-2 max-w-96">
              <InputBox
                label="Plate Number"
                name="plateNumber"
                type="text"
                placeholder="Enter Plate Number"
                onChange={handleChange}
                value={formData.plateNumber}
                required
                disabled={!editMode}
              />
              <InputBox
                label="Make or Model"
                name="model"
                type="text"
                placeholder="Enter Make or Model"
                onChange={handleChange}
                value={formData.model}
                required
                disabled={!editMode}
              />

              <InputBox
                label="Name Registered"
                name="nameRegistered"
                type="text"
                placeholder="Enter Name Registered"
                onChange={handleChange}
                value={formData.nameRegistered}
                required
                disabled={!editMode}
              />

              <InputBox
                label="Sticker Number"
                name="stickerNumber"
                type="text"
                placeholder="Enter Sticker Number Issued"
                onChange={handleChange}
                value={formData.stickerNumber}
                required
                disabled={!editMode}
              />

              {editMode && (
                <div className="">
                  <button
                    className="blue-button w-full mt-8"
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Submitting.." : "Submit"}
                  </button>
                </div>
              )}
            </form>

            {/* documents for the vehicles */}
            <div>
              <div className="header mt-8">Documents</div>
              <div>
                <button
                  className={uploadMode ? "underline" : "blue-button"}
                  onClick={handleUploadMode}
                >
                  {uploadMode ? "Cancel" : "Upload Documents"}
                </button>
              </div>
              {requiredDocuments.map((doc, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 mt-4 bg-gray-100 rounded-md shadow-sm max-w-96 min-h-16 p-2 justify-between flex-wrap"
                >
                  <div className="">
                    <div className="text-gray-600 text-sm font-bold">{doc}</div>
                    {uploadMode && (
                      <>
                        <input
                          type="file"
                          onChange={(e) => handleUpLoad(e, doc)}
                        />
                        {activeUpload === doc && (
                          <progress value={progress} max="100" />
                        )}
                      </>
                    )}
                  </div>
                  {documents[doc] ? (
                    <div>
                      <a
                        href={documents[doc]}
                        target="_blank"
                        rel="noreferrer"
                        className="bg-green-600 px-2 py-1 text-white rounded-md"
                      >
                        View
                      </a>
                    </div>
                  ) : (
                    <div>Not Uploaded</div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
