import React, { useEffect, useState } from "react";
import SelectInput from "../Components/SelectInput";
import {
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { doc } from "firebase/firestore";
import InputBox from "../Components/InputBox";
import ScheduledMaintenance from "./ScheduledMaintenance";

export default function ItemDetails({ item, setViewMode }) {
  const [formData, setFormData] = useState(item);
  const [isProcessing, setIsProcessing] = useState(false);
  const [updated, setUpdated] = useState(true);
  const [frequency, setFrequency] = useState("");

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchItem = async () => {
      const data = await getDocDataSnap("maintenanceItems", item.id, (data) => {
        setFormData(data);
      });
    };
    fetchItem();
  }, []);

  const frequencies = [
    "Daily",
    "Weekly",
    "Bi-Weekly",
    "Monthly",
    "Quarterly",
    "Semi-Annual",
    "Yearly",
  ];
  const frequenciesOptions = frequencies.map((item) => {
    return {
      value: item,
      label: item,
    };
  });

  const handleSelectFrequency = (selected) => {
    setUpdated(false);
    setFrequency(selected.value);
  };

  const handleUpdate = async () => {
    setIsProcessing(true);
    const docObject = {
      ...formData,
      frequency: frequency,
      updatedBy: currentUser.uid,
      updatedName: currentUser.displayName,
      updatedEmail: currentUser.email,
      updatedAt: new Date().toLocaleString(),
      updateType: "Maintenance Frequency",
    };
    await setDocData("maintenanceItems", item.id, docObject);
    toast.success("Item Updated Successfully");
    setUpdated(true);
    setIsProcessing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (formData.status === "Scheduled") {
      toast.error(
        "Item is already scheduled for maintenance, Edit the schedule instead"
      );
      setIsProcessing(false);
      return;
    }

    const docObject = {
      ...formData,
      nextSchedule: formData.nextSchedule,
      budget: formData.budget,
      supplier: formData.supplier,
      status: "Scheduled",
      scheduledBy: currentUser.uid,
      scheduledName: currentUser.displayName,
      scheduledEmail: currentUser.email,
      updateType: "Maintenance Schedule Set",
      scheduledAt: new Date().toLocaleString(),
    };
    await setDocData("maintenanceItems", item.id, docObject);
    toast.success("Maintenance Schedule Set Successfully");
    setIsProcessing(false);
  };

  return (
    <div className="bg-white w-full min-h-screen rounded-lg pt-8 relative p-4 flex items-start gap-4 flex-col lg:flex-row">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>
      <div className="w-80">
        <div className="header">{formData.name}</div>
        <div className="mt-4">
          <div className="w-80 flex items-center justify-between">
            <div>Item Description</div>
            <div>{formData.description}</div>
          </div>
          <div className="w-80 flex items-center justify-between">
            <div>Location</div>
            <div>{formData.location}</div>
          </div>

          <div className="w-80 flex items-center justify-between">
            <div>Frequency of Maintenance</div>
            <div>{formData.frequency || "Not Set"}</div>
          </div>

          <div className="w-80 flex items-center justify-between">
            <div>Last Maintenance</div>
            <div>{formData.lastMaintenanceSchedule || "Not Set"}</div>
          </div>

          <div className="w-80 flex items-center justify-between">
            <div>Last Maintenance Cost</div>
            <div>{formData.lastMaintenanceCost || "Not Set"}</div>
          </div>

          <div className="w-80 flex items-center justify-between">
            <div>Last Maintenance Supplier</div>
            <div>{formData.lastMaintenanceSupplier || "Not Set"}</div>
          </div>

          <div className="w-80 flex items-center justify-between">
            <div>Next Maintenance Schedule</div>
            <div>{formData.nextSchedule || "Not Set"}</div>
          </div>

          <div className="mt-4">
            <div className="header">Maintenance Schedule Information</div>
            <div className="mt-2">
              <div className="text-gray-600 font-bold">
                Set Frequency of Maintenance
              </div>
              <SelectInput
                options={frequenciesOptions}
                placeholder="Select Frequency"
                onChange={(selected) => handleSelectFrequency(selected)}
                name="frequency"
              />
              <button
                className="blue-button mt-2 disabled:bg-opacity-50"
                onClick={handleUpdate}
                disabled={isProcessing || updated}
              >
                Update
              </button>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="header">Set Schedule Data</div>
          <form onSubmit={handleSubmit}>
            <div className="space-y-2 mt-2">
              <InputBox
                name="nextSchedule"
                label="Next Schedule"
                type="date"
                placeholder="Enter next schedule"
                onChange={handleChange}
                required
              />

              <InputBox
                name="budget"
                label="Set Budget"
                type="number"
                placeholder="Enter Budget"
                onChange={handleChange}
                required
              />

              <InputBox
                name="supplier"
                label="Set Assigned Supplier"
                type="text"
                placeholder="Enter Assigned Supplier"
                onChange={handleChange}
                required
              />

              <button className="blue-button">Submit</button>
            </div>
          </form>
        </div>
      </div>

      {/* right side */}
      <div className="w-full">
        <div className="header">Current Scheduled Maintenance</div>
        <div>
          {formData.status === "Scheduled" ? (
            <div>
              <ScheduledMaintenance item={formData} id={item.id} />
            </div>
          ) : (
            <div>No Scheduled Maintenance Set</div>
          )}
        </div>
      </div>
    </div>
  );
}
