import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData } from "../../utils/firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import { setDocData } from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function GatePassDetails() {
  const { id } = useParams();
  const currentUser = useSelector((state) => state.userObject);
  const [gatepass, setGatepass] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [comments, setComments] = React.useState([]);
  const [newComment, setNewComment] = React.useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGatepass = async () => {
      const data = await getDocData("gatePass", id);
      setGatepass(data);
      setComments(data.comments || []);
      setIsLoading(false);
    };
    fetchGatepass();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Gate Pass Confirmation">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const date = gatepass.requestDateTime.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  console.log(gatepass);

  const handleAddComment = async () => {
    if (newComment.trim() === "") {
      toast.error("Comment cannot be empty");
      return;
    }

    const newComments = [
      ...comments,
      {
        createdBy: currentUser.displayName,
        requestedBy: currentUser.uid,
        email: currentUser.email,
        message: newComment,
        createdAt: new Date().toLocaleString(),
      },
    ];
    await setDocData("gatePass", id, { comments: newComments });
    toast.success("Comment added successfully");
    setComments(newComments);
    setNewComment("");
  };

  return (
    <UserScreenWindow label="Gate Pass Details">
      {/* details */}
      <div className="text-sm bg-white rounded-lg text-gray-600">
        <div className="bg-gray-300 p-2 font-bold text-center text-gray-600">
          Gate Pass Request Details
        </div>
        <div className="flex items-center justify-between px-2 mt-2">
          <div>Address</div>
          <div>{gatepass.identifier}</div>
        </div>

        <div className="flex items-center justify-between px-2">
          <div>Delivery From</div>
          <div>{gatepass.deliveryCompany}</div>
        </div>

        <div className="flex items-center justify-between px-2">
          <div>Date</div>
          <div>{gatepass.deliveryDate}</div>
        </div>

        <div className="flex items-center justify-between px-2">
          <div>Time</div>
          <div>{gatepass.deliveryTime}</div>
        </div>

        <div className="mt-4 px-2">
          <div>Confirmation no.</div>
          <div>{id}</div>
        </div>

        <div className="mt-4 px-2">
          <div className="font-bold text-gray-600">Items</div>
          <div>{gatepass.items}</div>
        </div>

        <div className="mt-4 px-2">
          <div className="font-bold">Status</div>
          <div className={`capitalize ${gatepass.status}`}>
            {gatepass.status}
          </div>
        </div>
      </div>

      {/* comments section */}
      <div className="mt-4">
        <div className="header">Comments</div>
        {comments.map((comment, index) => (
          <div key={index} className="comment mt-2 text-xs">
            <div className="commenter font-bold">{comment.createdBy}</div>
            <div className="comment-text">{comment.message}</div>
          </div>
        ))}

        <div className="mt-4">
          <textarea
            name="comment"
            id=""
            rows={4}
            className="w-full rounded-lg border border-gray-200 text-xs p-2"
            placeholder="Add a comment"
            onChange={(e) => setNewComment(e.target.value)}
            value={newComment}
          ></textarea>
        </div>

        <div className="text-right">
          <button className="blue-button" onClick={handleAddComment}>
            Add Comment
          </button>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default GatePassDetails;
