import React, { useEffect } from "react";
import { FiBell } from "react-icons/fi";
import { FiArrowDown } from "react-icons/fi";
import RequestsSection from "./RequestsSection";
import ServicesSection from "./ServicesSection";
import Announcements from "./Announcements";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/talilogo.jpg";
import {
  getDocDataSnap,
  queryIdentifiers,
  getDocById,
  getAllDocs,
  getAllDocsSnap,
  queryAllDocsByFieldSnap,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import QuickContactsSection from "./QuickContactsSection";
import toast from "react-hot-toast";
import { parse } from "uuid";
import UserScreenWindow from "./UserScreenWindow";

function UserDashboard() {
  const navigate = useNavigate();
  const [identifiers, setIdentifiers] = React.useState([]);
  const [outstandingBills, setOutstandingBills] = React.useState([]);
  const [billTotal, setBillTotal] = React.useState("...loading");
  const [isLoading, setIsLoading] = React.useState(true);
  const [activeRequests, setActiveRequests] = React.useState([]);
  const [notifications, setNotifications] = React.useState([]);
  const [unreadNotifications, setUnreadNotifications] = React.useState([]);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const unsubscribeNotifications = getAllDocsSnap("notifications", (data) => {
      const userNotifications = data.filter(
        (notification) => notification.user === currentUser.email
      );
      setNotifications(userNotifications);

      const unreadNotifications = userNotifications.filter(
        (notification) => notification.read === false
      );
      setUnreadNotifications(unreadNotifications);
      // if (unreadNotifications.length > 0) {
      //   toast.success(
      //     `You have ${unreadNotifications.length} unread notifications`
      //   );
      // }
    });

    // Cleanup function to unsubscribe
    return () => unsubscribeNotifications();
  }, [currentUser.email]); // Adding currentUser.email as a dependency

  useEffect(() => {
    const unsubscribeActiveRequests = getAllDocsSnap("workPermits", (data) => {
      //filter active requests by email
      const activeRequests = data.filter(
        (request) => request.createdBy === currentUser.email
      );
      setActiveRequests(activeRequests);
    });

    // Cleanup function to unsubscribe
    return () => unsubscribeActiveRequests();
  }, [currentUser.email]); // Adding currentUser.email as a dependency

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await getDocById("users", currentUser.uid);
      setIdentifiers(user.linkedIdentifiers);
    };
    fetchUserData();
  }, [currentUser.uid]); // Adding currentUser.uid as a dependency

  useEffect(() => {
    const fetchOutstandingBills = async () => {
      const allBills = [];
      for (const identifier of identifiers) {
        const data = await queryAllDocsByFieldSnap(
          "billings",
          "identifier",
          "==",
          identifier,
          (data) => {
            allBills.push(...data);
            setOutstandingBills(allBills);
            setIsLoading(false);
          }
        );
      }
    };

    if (identifiers?.length) {
      fetchOutstandingBills();
    }
  }, [identifiers]);

  //total all bills
  const totalBills = outstandingBills.reduce((acc, bill) => {
    if (!bill) {
      return acc;
    }
    return acc + parseFloat(bill.amount.replace(/,/g, ""));
  }, 0);

  const dateConverter = (timestamp) => {
    const date = new Date(timestamp);
    return date;
  };

  if (!identifiers) {
    return (
      <UserScreenWindow label="Error">
        <div className="text-center text-xl mt-4 text-blue-900">
          Your account has encountered an error. Please contact HOA to resolve
          the issue
        </div>
      </UserScreenWindow>
    );
  }

  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%" }}
      transition={{ duration: 0.3, type: "tween" }}
      className="flex flex-col items-start justify-center p-2 relative  max-w-[450px] m-auto w-screen"
    >
      <div
        className="absolute top-10 right-4 flex items-center"
        onClick={() => navigate("/notifications")}
      >
        <div className="flex items-center relative">
          {unreadNotifications.length > 0 && (
            <div className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
              {unreadNotifications.length}
            </div>
          )}
          <FiBell className="text-2xl text-gray-500" />
        </div>
      </div>
      <div className="w-full bg-white flex items-center justify-center py-2">
        <div className="w-12 m-auto">
          <img src={Logo} alt="" />
        </div>
        <div className="text-gray-600 text-xs ">Powered by</div>
        <div className="w-16 m-auto">
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713873813/elegho/Elegho-Logo_vah1pv.png"
            alt=""
          />
        </div>
      </div>
      <div className="relative bg-blue-900 w-full text-white rounded-lg p-2 ">
        <div
          className="px-2 py-1 bg-slate-100 text-neutral-900 rounded-xl w-fit right-2 bottom-2 absolute cursor-pointer"
          onClick={() => navigate("/bills")}
        >
          Pay now
        </div>
        <div className="flex items-center justify-between">
          <div>Outstanding Bill</div>
          <div
            className="underline cursor-pointer"
            onClick={() => navigate("/bills")}
          >
            View Details
          </div>
        </div>
        <div>
          <div className="text-left">
            <div className="text-4xl font-bold">
              Php{" "}
              {isLoading
                ? "..loading "
                : totalBills.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </div>
            <div className="text-xs ">Association Dues and Water</div>
            {/* <div className="text-xs text-orange-400">
              Due on
              {dateConverter(outstandingBills[0]?.dueDate).toLocaleDateString()}
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-4 w-full">
        <div
          className="rounded-xl bg-blue-900 text-white mt-2 flex items-center justify-center overflow-hidden h-12 border border-blue-900 cursor-pointer"
          onClick={() => navigate("/requests")}
        >
          <div className="flex items-center justify-center h-full">
            <div className="font-bold text-yellow-500 text-4xl p-2">
              {activeRequests.length}
            </div>
            <div className="text-xs uppercase w-16 h-full py-2">
              Active Requests
            </div>
          </div>
          <div className="bg-white text-blue-900 h-full p-2 text-xs flex flex-col items-center justify-center">
            <div>View All</div>
            <div>
              <FiArrowDown />
            </div>
          </div>
        </div>

        <div
          className="rounded-xl bg-blue-900 text-white mt-2 flex items-center justify-center overflow-hidden h-12 border border-blue-900 cursor-pointer"
          onClick={() => navigate("/tasks")}
        >
          <div className="flex items-center justify-center h-full">
            <div className="font-bold text-yellow-500 text-4xl p-2">0</div>
            <div className="text-xs uppercase w-16 h-full py-2">
              Pending Tasks
            </div>
          </div>
          <div className="bg-white text-blue-900 h-full p-2 text-xs flex flex-col items-center justify-center">
            <div>View All</div>
            <div>
              <FiArrowDown />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <RequestsSection />
      </div>

      <div className="w-full">
        <QuickContactsSection />
      </div>

      <div className="max-w-[380px] m-auto w-full">
        <Announcements />
      </div>
    </motion.div>
  );
}

export default UserDashboard;
