import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import SelectInput from "../Dashboard/Components/SelectInput";
import InputBox from "../Login/InputBox";
import { addNewDoc } from "../../utils/firebase/Firebase.utils";

export default function CreateBillingPage() {
  const [residents, setResidents] = React.useState([]);
  const [loading, setIsLoading] = React.useState(true);
  const [billingArray, setBillingArray] = React.useState([]);

  const billingFormat = {
    identifier: "",
    dueDate: "",
    name: "",
    billingDate: "",
    soaNumber: "",
    billingType: "",
    description: "",
    status: "unpaid",
    amount: 0,
  };

  useEffect(() => {
    const fetchAllResidents = async () => {
      await getAllDocsSnap("residents", (data) => {
        setResidents(data);
        setIsLoading(false);
      });
    };
    fetchAllResidents();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  console.log(residents);

  const residentOptions = residents.map((resident) => {
    return { value: resident.identifier, label: resident.identifier };
  });

  const handleAddBilling = () => {
    setBillingArray([...billingArray, billingFormat]);
  };

  const handleRemove = (index) => {
    setBillingArray(billingArray.filter((_, i) => i !== index));
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedArray = [...billingArray];
    updatedArray[index] = { ...updatedArray[index], [name]: value };
    setBillingArray(updatedArray);
  };

  const handleSelect = (e, index) => {
    const { label, value } = e;
    const updatedArray = [...billingArray];
    updatedArray[index] = {
      ...updatedArray[index],
      identifier: value,
      name: residents.find((resident) => resident.identifier === value).owner1,
    };
    setBillingArray(updatedArray);
  };

  console.log(billingArray);

  const handleSave = async () => {
    const batch = [];
    billingArray.forEach((bill) => {
      batch.push(addNewDoc("billings", bill));
    });
    await Promise.all(batch);
    setBillingArray([]);
  };

  return (
    <div className="text-left">
      <div className="header">Create Billing</div>

      <button onClick={handleAddBilling} className="blue-button">
        Add
      </button>

      {billingArray.map((bill, index) => (
        <div
          className="flex items-center gap-4 border-b border-gray-300 pb-4 text-xs"
          key={index}
        >
          <div className="w-48 ">
            <SelectInput
              name="identifier"
              label="Resident"
              options={residentOptions}
              onChange={(e) => handleSelect(e, index)}
            />
          </div>

          <InputBox
            name="name"
            label="Name"
            type="text"
            placeholder="Enter Name"
            onChange={(e) => handleChange(e, index)}
            value={billingArray[index].name}
            disabled
          />

          <InputBox
            name="billingDate"
            label="Billing Date"
            type="date"
            placeholder={"Select Date of Billing"}
            onChange={(e) => handleChange(e, index)}
          />

          <InputBox
            name="dueDate"
            label="Due Date"
            type="date"
            placeholder={"Select Due Date"}
            onChange={(e) => handleChange(e, index)}
          />

          <InputBox
            name="soaNumber"
            label="SOA Number"
            type="text"
            placeholder="Enter SOA Number"
            onChange={(e) => handleChange(e, index)}
          />

          <InputBox
            name="billingType"
            label="Billing Type"
            type="text"
            placeholder="Enter Billing Type"
            onChange={(e) => handleChange(e, index)}
          />

          <InputBox
            name="description"
            label="Description"
            type="text"
            placeholder="Enter Description"
            onChange={(e) => handleChange(e, index)}
          />

          <InputBox
            name="amount"
            label="Amount"
            type="number"
            placeholder="Enter Amount"
            onChange={(e) => handleChange(e, index)}
          />

          <button onClick={() => handleRemove(index)} className="green-button">
            Remove
          </button>
        </div>
      ))}

      <button className="blue-button" onClick={handleSave}>
        Save
      </button>
    </div>
  );
}
