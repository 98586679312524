import React from "react";
import InputBox from "../Components/InputBox";
import toast from "react-hot-toast";
import { setDocData } from "../../../utils/firebase/Firebase.utils";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "../Components/Modal";

export default function TenancyInformation({ tenant }) {
  const { id } = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const [formData, setFormData] = React.useState(tenant);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isProcesssing, setIsProcessing] = React.useState(false);

  const currentUser = useSelector((state) => state.userObject);

  const allowableAccess = ["admin", "maker", "super-admin"];
  const userRoles = currentUser.roles;

  const handleEditMode = () => {
    setEditMode(!editMode);

    if (editMode) {
      if (window.confirm("Your changes will not be saved")) {
        setFormData(tenant);
        setEditMode(false);
        toast.error("Changes Discarded");
      }
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to save the changes?")) {
      setIsProcessing(true);
      const docObject = {
        ...formData,
        updatedAt: new Date().toLocaleString(),
        updatedBy: currentUser.email,
      };
      await setDocData("tenants", id, docObject);
      setEditMode(false);
      setIsProcessing(false);
      toast.success("Changes Saved");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="text-xs lg:text-sm text-gray-600 max-w-[1440px] space-y-4">
      {allowableAccess.some((role) => userRoles.includes(role)) ? (
        <button
          onClick={handleEditMode}
          className={editMode ? "underline text-xs mt-4" : "mt-4 blue-button"}
        >
          {editMode ? "Cancel" : "Edit"}
        </button>
      ) : null}

      <div className="bubble p-2 lg:p-4 space-y-2 mt-4">
        <form action="" onSubmit={handleSave} className="max-w-[500px]">
          <div className="space-y-4">
            <InputBox
              label="Monthly Lease Rate"
              type="number"
              name="monthlyLeaseRate"
              required
              disabled={!editMode}
              placeholder={"120000"}
              value={formData.monthlyLeaseRate}
              onChange={handleChange}
            />
            <InputBox
              label="No. of Months Deposit"
              type="number"
              name="monthsDeposit"
              required
              placeholder={"2"}
              disabled={!editMode}
              value={formData.monthsDeposit}
              onChange={handleChange}
            />
            <InputBox
              label="No. of Months Advance"
              type="number"
              name="monthsAdvance"
              required
              placeholder={"6"}
              disabled={!editMode}
              value={formData.monthsAdvance}
              onChange={handleChange}
            />
            <InputBox
              label="Mode of Payment"
              type="text"
              name="modeOfPayment"
              required
              disabled={!editMode}
              placeholder={"Cash, PDC, Bank Transfer"}
              value={formData.modeOfPayment}
              onChange={handleChange}
            />
            <InputBox
              label="Number of Allowed Occupants"
              type="number"
              name="allowedOccupants"
              required
              disabled={!editMode}
              placeholder={"4"}
              value={formData.allowedOccupants}
              onChange={handleChange}
            />
          </div>
          {editMode && (
            <div className="mt-4">
              <button className="w-full lg:w-fit blue-button">{"Save"}</button>
            </div>
          )}
        </form>
      </div>

      {isProcesssing && (
        <Modal>
          <div className="header">Saving changes...</div>
        </Modal>
      )}
    </div>
  );
}
