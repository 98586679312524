import React from "react";

function GatePassRequestCard({ gatepass }) {
  return (
    <div className="bg-white p-2 rounded-xl text-xs text-gray-600 hover:bg-blue-200 cursor-pointer relative">
      <div className="uppercase font-semibold ">{gatepass.deliveryCompany}</div>
      <div>
        {gatepass.deliveryDate} {gatepass.deliveryTime}
      </div>
      <div>{gatepass.items}</div>
      <div>
        <div className={`absolute top-1 right-2 capitalize ${gatepass.status}`}>
          {gatepass.status}
        </div>
      </div>
    </div>
  );
}

export default GatePassRequestCard;
