import React from "react";
import { convertTimeStamptoDate } from "../../utils/EleghoUtils";

function NotificationCard({ notification }) {
  console.log(notification);

  return (
    <div className="border-b p-2 bg-white rounded-xl text-xs hover:bg-blue-200 mt-2 text-left">
      <div
        className={`font-bold  uppercase ${
          notification.read === false ? "text-blue-900" : "text-gray-400"
        } `}
      >
        <div className="flex items-center gap-2">
          {notification.read === false && (
            <div className="bg-green-600 rounded-full h-[8px] w-[8px]"> </div>
          )}
          {notification.subject}
        </div>
      </div>
      <div className="capitalize-first">{notification.message}</div>
      <div className="text-xs text-gray-400">
        {convertTimeStamptoDate(notification.dateTime)}
      </div>
    </div>
  );
}

export default NotificationCard;
