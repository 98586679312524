import React from "react";
import {
  getDocDataSnap,
  setDocData,
  storage,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function ResidentOccupantDocuments(
  { occupantId } // Add occupantId as a prop
) {
  const [editMode, setEditMode] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [resident, setResident] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [progress, setProgress] = React.useState(0);
  const { id } = useParams();
  const [activeUpload, setActiveUpload] = React.useState("");

  React.useEffect(() => {
    const fetchOccupant = async () => {
      await getDocDataSnap("residents", id, (data) => {
        const occupantItem = data.occupantsName.find(
          (o) => o.id === occupantId
        );
        setFormData(occupantItem);
        setResident(data);
        setIsLoading(false);
      });
    };
    fetchOccupant();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleUpload = (e, doc) => {
    e.preventDefault();
    setActiveUpload(doc);
    const file = e.target.files[0];
    const storageRef = ref(storage, `residents/occupants/${id}/${doc}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await setDocData("residents", id, {
            occupantsName: resident.occupantsName.map((occupant) => {
              if (occupant.id === occupantId) {
                return {
                  ...occupant,
                  documents: {
                    ...occupant.documents,
                    [doc]: downloadURL,
                  },
                };
              }
              return occupant;
            }),
          });

          toast.success(`${doc} Uploaded Successfully`);
        });
      }
    );
  };

  const requiredDocuments = ["Government ID", "Secondary ID"];

  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="header">Occupant Documents</div>

        <div>
          <button
            className={editMode ? "underline" : "blue-button"}
            onClick={handleEditMode}
          >
            {editMode ? "Cancel" : "Edit Documents"}
          </button>
        </div>
      </div>

      <div className="flex flex-col items-start gap-4 mt-4">
        {requiredDocuments.map((doc, index) => (
          <div
            key={index}
            className="flex lg:flex-row lg:items-center gap-4 flex-col "
          >
            <div className="w-60">{doc}</div>
            {editMode && (
              <>
                <input type="file" onChange={(e) => handleUpload(e, doc)} />
                {activeUpload === doc && progress > 0 && (
                  <progress value={progress} max="100" />
                )}
              </>
            )}
            {formData.documents && formData.documents[doc] ? (
              <a
                href={formData.documents[doc]}
                target="_blank"
                rel="noreferrer"
              >
                View
              </a>
            ) : (
              <div>No File Uploaded</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
