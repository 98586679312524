import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import GatePassCard from "./GatePassCard";
import PageModal from "../Dashboard/Components/PageModal";
import GatePassDetailsPage from "./GatePassDetailsPage";

export default function GatePass() {
  const [gatePassRequests, setGatePassRequests] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);

  useEffect(() => {
    const getGatePassRequests = async () => {
      await getAllDocsSnap("gatePass", (data) => {
        setGatePassRequests(data);
        setLoading(false);
      });
    };

    getGatePassRequests();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleViewMode = (item) => {
    setViewMode(true);
    setSelectedData(item);
  };

  return (
    <div className="mt-8">
      <div className="header">Gate Pass Requests</div>
      <div className="mt-4 space-y-2">
        {gatePassRequests.map((item, index) => (
          <div key={index} onClick={() => handleViewMode(item)}>
            <GatePassCard key={index} data={item} />
          </div>
        ))}
      </div>

      {viewMode && (
        <PageModal>
          <GatePassDetailsPage data={selectedData} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
